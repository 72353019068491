import React, { useEffect, useState, useRef } from 'react';
import _isEmpty from 'lodash.isempty';
import { FormModal, Loader, useSocketContext } from '@shootsta/common-react';
import {
  FlexPurchasePaymentSuccessModal,
  PaymentFailedModal,
  PaymentSuccessfulModal,
  ProjectSubmissionPaymentSuccessModal,
  PAYMENT_TYPES
} from '@core/shared';

interface PaymentStatusModalProps {
  history: any;
}

interface PaymentSuccessEventType {
  orderId: string;
  status: string;
  totalCreditPurchased: number;
}

enum PaymentStatusEnum {
  PROCESSING = 'processing',
  SUCCESS = 'success',
  CANCELLED = 'cancelled'
}

function handleLocationParams(search: string, hash: string) {
  if (_isEmpty(search) && _isEmpty(hash)) {
    return {
      paymentStatus: null,
      orderId: null,
      type: null
    };
  }

  // check if the payment status values present in search
  if (!_isEmpty(search)) {
    const searchParams = new URLSearchParams(search);
    const paymentStatus = searchParams.get('paymentStatus');
    const orderId = searchParams.get('orderId');
    const type = searchParams.get('type');
    return {
      paymentStatus,
      orderId,
      type
    };
  }

  // get the paymentStatus, orderId and type from hash
  const queryString = hash.split('?')[1];
  const searchParams = new URLSearchParams(queryString);
  const paymentStatus = searchParams.get('paymentStatus');
  const orderId = searchParams.get('orderId');
  const type = searchParams.get('type');

  return {
    paymentStatus,
    orderId,
    type
  };
}

function PaymentStatusModal({ history }: PaymentStatusModalProps) {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const { socket } = useSocketContext();

  const {
    location: { search = '', hash = '' }
  } = history;

  const searchParams = new URLSearchParams(search);
  const paymentStatusParam = searchParams.get('paymentStatus');
  const orderIdParam = searchParams.get('orderId');
  const orderTypeParam = searchParams.get('type');

  const [paymentStatus, setPaymentStatus] = useState<PaymentStatusEnum | null>(
    paymentStatusParam as PaymentStatusEnum
  );
  const [orderId, setOrderId] = useState<string | null>(orderIdParam);
  const [orderType, setOrderType] = useState<string | null>(orderTypeParam);
  const [purchasedCredit, setPurchasedCredit] = useState(0);

  function onClosePaymentModal() {
    setPaymentStatus(null);
  }

  function handleOrderEvent(order: PaymentSuccessEventType) {
    const { orderId: receivedOrderId, totalCreditPurchased } = order;

    if (orderId !== receivedOrderId) {
      return;
    }

    setPaymentStatus(PaymentStatusEnum.SUCCESS);
    setPurchasedCredit(totalCreditPurchased);

    if (!timeoutRef.current) {
      return;
    }

    clearTimeout(timeoutRef.current);
    timeoutRef.current = null;
  }

  function onClickViewInvoice() {
    // TODO: handle invoice
  }

  function removeUrlParams(param: string) {
    if (!search && !hash) return;

    let newUrl = history.location.pathname;

    // Handle query params in the search field
    if (search) {
      const searchParams = new URLSearchParams(search);
      searchParams.delete(param);
      newUrl += searchParams.toString() ? `?${searchParams.toString()}` : '';
    }

    // Handle query params in the hash field
    if (hash) {
      const [hashValue] = hash.split('?');
      newUrl += hashValue;
    }

    // Update the URL
    history.replace(newUrl);
  }

  useEffect(() => {
    socket.connect();

    const onConnect = () => console.log('Socket connected');
    const onDisconnect = () => console.log('Socket disconnected');

    socket.on('connect', onConnect);
    socket.on('connect_error', onDisconnect);
    socket.on('error', onDisconnect);
    socket.on('disconnect', onDisconnect);

    socket.on(`orderId|${orderId}`, (order: PaymentSuccessEventType) => {
      handleOrderEvent(order);
    });

    return () => {
      socket.disconnect();

      if (!timeoutRef.current) {
        return;
      }
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    };
  }, []);

  useEffect(() => {
    if (!search && !hash) return;

    const {
      paymentStatus: orderPaymentStatus,
      orderId: orderIdFromParams,
      type
    } = handleLocationParams(search, hash);

    if (type) {
      setOrderType(type);
      removeUrlParams('type');
    }

    if (orderIdFromParams) {
      setOrderId(orderIdFromParams);
      removeUrlParams('orderId');
    }

    if (!orderPaymentStatus) {
      return;
    }

    if (orderPaymentStatus === PaymentStatusEnum.SUCCESS) {
      setPaymentStatus(PaymentStatusEnum.PROCESSING);
      removeUrlParams('paymentStatus');

      timeoutRef.current = setTimeout(
        () => window.location.reload(),
        2 * 60 * 1000
      );
      return;
    }

    setPaymentStatus(orderPaymentStatus as PaymentStatusEnum);
    removeUrlParams('paymentStatus');
  }, [search, hash]);

  if (paymentStatus === PaymentStatusEnum.PROCESSING) {
    return (
      <FormModal visible hideFooter showHeaderCloseButton={false}>
        <Loader
          hasNoBeforeShowTimeout
          text="Please wait a moment while we securely process your payment. Do not close or refresh the page."
          caption={null}
        />
      </FormModal>
    );
  }

  if (paymentStatus === PaymentStatusEnum.SUCCESS) {
    if (orderType === PAYMENT_TYPES.FLEX) {
      return (
        <FlexPurchasePaymentSuccessModal
          visible
          purchasedCredit={purchasedCredit}
          onViewInvoice={onClickViewInvoice}
          onClose={onClosePaymentModal}
        />
      );
    }

    if (orderType === PAYMENT_TYPES.PROJECT_COMPLETED) {
      return (
        <PaymentSuccessfulModal
          visible
          onViewInvoice={onClickViewInvoice}
          onClose={onClosePaymentModal}
        />
      );
    }

    if (orderType === PAYMENT_TYPES.PROJECT_SUBMISSION) {
      return (
        <ProjectSubmissionPaymentSuccessModal
          visible
          onViewInvoice={onClosePaymentModal}
        />
      );
    }
  }

  if (paymentStatus === PaymentStatusEnum.CANCELLED) {
    return <PaymentFailedModal visible onClose={onClosePaymentModal} />;
  }

  return null;
}

export default PaymentStatusModal;
